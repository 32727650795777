.DesktopNav nav {
    padding-top: 20px;
    height: 50px;
    width: 100%;
    background: #161a30;
    box-shadow: 2px 4px 55px #0e0e0e;
    position: fixed;
    z-index: 9;
    font-weight: 700;
}
.DesktopNav img {
    width: 80px;
    background-color: #161a30;
    padding: 5px 20px;
    border-radius: 80px;
    margin-top: -12px;
}
.DesktopNav nav a {
    padding: 20px;
    margin-top: 10px;
    color: rgb(245, 245, 245);
}
.DesktopNav ul {
    display: flex;
    flex-direction: row;
    width: 85%;
    margin: auto;
}

.DesktopNav li {
  list-style: none;
  margin-right: 40px;
}

@media (max-width: 65em){
    .DesktopNav ul {
        width: 89%;
    }
}
@media (min-width: 90em){
    .DesktopNav ul {
        width: 72%;
    }
}@media (min-width: 75em){
    .DesktopNav nav{
        height: 60px;
        font-size: 1.3em;
    }
    .DesktopNav img {
        width: 100px;
        padding-top: 5px;
    }
    .DesktopNav .dashboard {
        margin-top: 10px;
    }
}
@media (min-width: 110em){
    .DesktopNav nav{
        height: 100px;
        font-size: 1.9em;
    }
    .DesktopNav img {
        width: 130px;
        margin-top: -2px;
    }
    .DesktopNav .dashboard {
        margin-top: 20px;
    }
    
}
@media (min-width: 140em){
    .DesktopNav ul {
        width: 65%;
    }
}

@media (max-width: 63em){
}

@media (max-width: 46em){
}
@media (max-width: 38em){
}
@media (max-width: 27em){
}