nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 160vh;
  width: 300px;
  z-index: 20;
}
body {
  background: white;
  padding: 0;
  margin: 0;
}
.bar {
  width: 100%;
  height: 70px;
  background-color: #191d36;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: linear-gradient(180deg, #191d36 0%, #324b6e 100%);
}

button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  z-index: 21;
}
ul,
li {
  margin: 0;
  padding: 0;
}

.MobileNav ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

.MobileNav li {
  list-style: none;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

a {
  color: white;
  text-decoration: none;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
