.PageComponent {
    padding: 160px 0;
    background-color: rgb(245, 245, 245);
    color: #131313;
    height: 600px;
}
.PageComponent .container {
    width: 85%;
    margin: auto;
}
.PageComponent p {
    margin-top: 15px;
}
.PageComponent h3 {
    margin-top: 15px;
    text-align: center;
}
.PageComponent h2 {
    margin-bottom: 5px;
    font-size: 2em;
}
.card-container {
    display: flex;
    justify-content: space-between;
} 
.card {
    margin-top: 70px;
    height: 350px;
    background-color: rgb(245, 245, 245);
    box-shadow: 2px 2px 15px #3f3f3f;
    padding: 40px;
    border-radius: 20px;
    justify-content: center;
    position: relative;
    display: grid;
    width: 21%;
    text-align: justify;
}
.imageDiv {
    width: 100px;
    margin: auto;
}

@media (min-width: 75em){
    .imageDiv img{
        height: 100px;
    }
    .PageComponent {
        padding-top: 200px;
        height: 750px;
    }
    .PageComponent .container {
        width: 80%;
    }
    .card {
        margin: auto;
        padding: 35px !important;
        margin-top: 130px;
        width: 24%;
        font-size: 1.35em;
        height: 500px;
        border-radius: 35px;
    }
    .PageComponent h2 {
        width: 93%;
        margin: auto;
    }
}
@media (min-width: 119em){
    .PageComponent {
        height: 800px;
    }
    .PageComponent .container {
        width: 75%;
    }
    .card {
        margin: auto;
        padding: 40px !important;
        margin-top: 130px;
        width: 25%;
        font-size: 1.8em;
        height: 600px;
        border-radius: 35px;
    }
    .PageComponent h2 {
        width: 93%;
        margin: auto;
    }
    .imageDiv {
        text-align: center;
    }
}
@media (min-width: 140em){
    .PageComponent .container {
        width: 65%;
        margin: auto; 
    }
}

@media (max-width: 63em){
    .PageComponent .container {
        width: 94%;
        margin: auto;
    }
    .card {
        padding: 18px;
        margin: auto;
        margin-top: 70px;
        width: 24%;
        height: 400px;
    }
    .PageComponent h2 {
        width: 95%;
        margin: auto;
    }
    .imageDiv {
        text-align: center;
    }

}
@media (max-width: 46em){
    .PageComponent {
        height: 1500px;
    }
    .PageComponent .container {
        text-align: center;
        height: 1500px;
    }
    .card-container {
        flex-direction: column;
    } 
    .card {
        width: 80%;
        color: rgb(49, 46, 77);
        margin: auto;
        margin-top: 70px;
    }

}
@media (max-width: 38em){
    .card {
        padding: 25px;
        font-size: 0.8em;
    }
}
@media (max-width: 27em){
    html {
        font-size: 1.2em;
    }
}