.Part {
    width: 80%;
    margin: auto; 
    margin-top: 240px;
    /* text-align: center; */
}
.Part p {
    margin: 30px 0;
    width: 45%;
}
.Part h2 {
    font-size: 1.7em;
    padding-bottom: 40px;
}
.containerD {
    position: relative;
    width: 500px;
    height: 400px;
    display: grid;
    margin-top: -100px;
    overflow:visible;
}
.Product {
    border-radius: 50%;
    grid-column: 1;
    position: absolute;
    grid-row: 1;
    width: 100%;
    z-index: 4;
}
.containerPart {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
}
.containerPart p {
    width: 50%;
}
.Product div {
    overflow: visible !important;
}
@media (max-width: 65em){
    .containerD {
        margin: auto;
        margin-top: -70px;
    }
    .containerPart {
        flex-direction: row;
    }
    .containerPart p {
        width: 45%;
    }
}
@media (max-width: 46em){
    .Part {
        width: 75%;
        text-align: center;
        margin: auto; 
        margin-top: 140px;
    }
    .containerPart {
        flex-direction: column;
    }
    .containerPart p {
        width: 100%;
    }
}

@media (min-width: 90em){
    .containerD {
        margin-top: -100px;
        width: 800px;
        height: 900px;
    }
    .containerPart {
        flex-direction: row;
    }
}
@media (min-width: 140em){
    .Part {
        width: 65%;
        margin-top: 250px;
    }
}
@media (min-width: 75em){
    .Part {
        font-size: 1.4em;
    }
}
@media (min-width: 110em){
    .Part {
        font-size: 2em;
    }
    .containerD {
        margin-top: -270px;
        width: 1300px;
        height: 1400px;
    }
}
@media (min-width: 110em){
    .Part {
        font-size: 2em;
    }
}

@media (max-width: 63em){
}

@media (max-width: 46em){
    .containerD {
        margin: auto;
    }
}
@media (max-width: 38em){
    .containerPart {
        flex-direction: column;
    }
    .containerPart p {
        width: 100%;
        text-align: justify;
    }
}
@media (max-width: 27em){
    .containerD {
        width: 100%;
        margin-top: 0;
    }
}