.Footer {
    background-color: #161a30;
    margin-top: 300px;
    height: 200px;
    margin-bottom: 0;
    overflow: hidden;
}
.Footer .container {
    padding: 60px;
    width: 90%;
    margin: auto;
}
.Footer .Icons .container{
    padding: 0;
}
.Footer .Icons p{
    margin-top: 15px;
    font-size: 1.5em;
}
@media (min-width: 75em){
}
@media (min-width: 90em){
}
@media (min-width: 119em){
    .Footer .container {
        width: 70%;
    }
    .Footer .Icons img {
        width: 130px;
        height: 80px;
    }
    .Footer .Icons {
        font-size: 2em;
    }
}

@media (max-width: 63em){
    .Footer .container {
        padding: 30px;
    }
    .Footer .Icons {
        width: 500px;
    }
}
@media (max-width: 46em){
    .Footer .container {
        padding: 5px;
    }
    .Footer .Icons .container{
        width: 400px;
    }
    .Footer .Icons .container div{
        width: 62px !important;
        height: 40px !important;
    }
    .Footer .Icons {
        width: 400px;
        padding-top: 40px;
    }
}
@media (max-width: 38em){
}
@media (max-width: 30em){

}