.Icons {
    width: 100%;
    z-index: 10;
}
.Icons .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 360px;
    font-size: 0.35em !important;
    margin-left: -32.9px;
    color: rgb(245, 245, 245);
}

.Icons .container div {
    width: 110px; 
    height: 55px;
    z-index: 100;
    text-align: center;
}

.Icons img {
    height: 90%;
    margin-bottom: -10px;
}
@media (min-width: 75em){
    .Icons .container div {
        width: 135px;
        height: 80px;
    }
}
@media (min-width: 119em){
    
}

@media (max-width: 63em){
    .Icons {
        width: 550px;
        margin: auto;
    }
    .Icons .container {
        padding-top: 30%;
    }
    .Icons .container div {
        height: 45px;
        width: 70px;
    }
}

@media (max-width: 46em){
    .Icons {
        width: 550px;
        margin: auto;
    }
    .Icons .container {
        padding-top: 20px;
        justify-content: space-around;
    }
}
@media (max-width: 38em){
    .Icons {
        width: 370px;
        margin: auto;
    }
    .Icons .container {
        padding-top: 20px;
        margin-left: 0;
    }
    .Icons .container div {
        width: 60px; 
    }
    .Icons .container img {
        height: 35px;
    }
}
@media (max-width: 27em){
    .Icons {
        width: 300px;
    }
    .Icons .container {
        padding-top: 20px;
        margin-left: 0;
    }
    .Icons .container div {
        width: 40px; 
    }
    .Icons .container img {
        height: 30px;
        padding-bottom: 7px;
    }
}