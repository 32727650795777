.Dashboard {
    width: 80%;
    margin: auto;
    margin-top: 100px;
    text-align: center;
    color: rgb(59, 59, 59);
    font-size: 2em;
}
.Dashboard img {
    width: 50%;
    margin: auto;
    margin-bottom: 50px;
}
.lighter {
    background-color: #4c568a;
    border-radius: 3px;
}
.containerOverview, .containerOverview .container div {
    text-align: left;
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    flex-wrap: wrap;
}
.containerOverview {
    background-color: #161a30;
    color: rgb(245, 245, 245);
    border-radius: 10px;
}
.containerOverview .container {
    width: 38%;
    padding: 10px 40px 40px 40px;
}
.containerOverview .container div {
    max-height: 150px;
}
.containerOverview h5, .containerOverview h6 {
    margin: 10px; 
}
.containerOverview h3 {
    width: 100%;
    text-align: center;
    margin: 30px 5px 5px 5px;
}

.charts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.containerChart {
    padding: 30px;
}
.containerTipBox {
    position: relative;
    width: 80%;
    margin: auto;
}
.ToolTipBox {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 12px;
    padding: 5px 12px;
    border-radius: 3px;
    transform-origin: center center;
    z-index: 8;
    width: 30%;
    border-radius: 7px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) !important;
}
.hugeText .ToolTipBox {
    width: 82% !important;
    margin-top: -40px;
}
.children {
    margin-left: -20%;
}
.XYPlotRadial {
    position: relative;
}
.labelsRadial, .radialChart {
    position: absolute;
    top: 0;
    left: 0;
}
.radialChart .rv-xy-plot__series {
    transform: translate(80px, 70px);
    z-index: 10;
}

@media (min-width: 90.01em){
    .containerChart {
        padding: 80px;
    }.Dashboard {
        width: 75%;
        margin-top: 170px;
    }
    .containerOverview {
        justify-content: center;
    }
    .containerOverview .container {
        font-size: 1.5em;
        width: 42%;
    }
    .radialChart .rv-xy-plot__series {
        transform: translate(130px, 120px);
        z-index: 10;
    }
}
@media (min-width: 110em){
}

@media (min-width: 140em){
}
@media (min-width: 165em){
}

@media (max-width: 63em){
    .containerOverview .container {
        width: 100%;
        margin-top: 50px;
    }
}
@media (max-width: 46em){
.charts {
    justify-content: center;
}
}
@media (max-width: 39em){
    .Dashboard {
        width: 90%;
    }
    .containerOverview .container div {
    font-size: 0.6em;
}
    .containerChart {
        width: 100%;
        margin: auto !important;
    }
}
@media (max-width: 27em){
}