body {
    margin: 0; 
    padding: 0; 
}
.Header {
    margin: 0; 
    padding: 0;
    padding-top: 12%;
    background: #161a30;
    height: 1600px; 
    color: rgb(245, 245, 245); 
    overflow: hidden;
}
.Header .logo {
    width: 130%;
    margin-left: -50px; 
    margin-top: 6%;
}
.headerContainer {
    display: flex; 
    flex-direction: column-reverse;
    width: 83%;
    justify-content: space-between;
    margin: auto;
}
.headerContainer h2 {
    margin: 0;
    color: #a7a7a7;
}
.headerContainer .content {
    margin-top: 150px;
    font-size: 2em;
    z-index: 3;
    margin: auto;
}
.headerContainer .content h1 {
    font-weight: 700;
}
.imageContainer {
    width: 40rem;
    height: 40rem;
    margin: auto;
    margin-top: 290px;
    position: relative;
    display: grid;
    justify-content: center;
    align-content: center;
    overflow:visible;
}
.svgContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imageContainer div{
    margin: auto;
    position: absolute;
    width: 100%;
    height: 100%;
}
.platzhalter {
    grid-column: 1;
    position: absolute;
    grid-row: 1;
    width: 100%;
    z-index: 4;
}
.smaller {
    width: 100%;
}
.animtedBackground {
    position: absolute;
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: 100%;
    z-index: 3;
    overflow: visible;
}

.none {
    display: none;
}

@media (min-width: 90em){
    .Header {
        padding-top: 140px;
        height: 1400px;
    }
    .headerContainer {
        width: 70%;
        flex-direction: row;
    }
    .headerContainer .content {
        width: 40%;
        font-size: 2.5em;
        margin-top: 400px;
        padding-left: 13%;
        
    }
    .Header .logo {
        width: 600px;
        margin-left: -340px;
        margin-bottom: 100px;
    }
    .Header .Icons {
        width: 250%;
        margin-left: -15%;
    }
}
@media (min-width: 110em){
    .Header .Icons {
        width: 270%;
        margin-left: -45%;
    }
    .Header .Icons img {
        height: 115%;
    }
}

@media (min-width: 140em){
    .Header {
        height: 1550px;
    }
    .headerContainer {
        padding-top: 20px;
        width: 78%;
    }
    .Header .logo {
        margin: -70px 0 90px -290px;
        width: 800px;
    }
    .imageContainer {
        margin-left: 0;
    }
    .Header .Icons {
        width: 180%;
        margin-left: -20%;
        font-size: 2em;
    }
    .Header .Icons img {
        height: 135%;
    }
}
@media (min-width: 165em){
    .headerContainer {
        padding-top: 50px;
        width: 55%;
    }
}

@media (max-width: 63em){
    .Header {
        padding-top: 90px;
        height: 970px; 
    }
    .headerContainer {
        width: 90%;
        flex-direction:column-reverse;
    }
    .headerContainer .content {
        width: 100%;
        font-size: 2em;
        margin-top: -70px;
    }
    .imageContainer {
        width: 30rem;
        height: 30rem;
        padding-top: 150px;
        margin: auto;
    }
    .Header .logo {
        width: 120%;
        margin: 80px 0 0 -10px;
    }
    .Header .Icons  {
        margin-top: 50px;
    }
}
@media (max-width: 46em){
    .Header {
        padding-top: 90px;
        height: 1400px; 
    }
    .headerContainer {
        width: 90%;
        flex-direction: column;
    }
    .headerContainer .content {
        width: 100%;
        text-align: center;
        font-size: 2.3em;
        margin-top: 20px;
        padding-left: 0;
    }
    .headerContainer h1, .headerContainer h2 {
        margin: 0px;
    }
    .imageContainer {
        width: 30rem;
        height: 30rem;
        margin: auto;
        margin-top: 190px;
    }
    .Header .logo {
        width: 70%;
        margin: 80px 0 90px -40px;
    }
}
@media (max-width: 39em){
    .Header {
        padding-top: 130px;
        height: 1150px; 
    }
    .headerContainer {
        width: 100%;
    }
    .headerContainer .content {
        font-size: 1.7em;
        padding-left: 0;
    }
    .imageContainer {
        width: 22rem;
        height: 22rem;
        margin: auto;
        margin-top: 90px;
    }
}
@media (max-width: 27em){
    .Header {
        padding-top: 100px;
        height: 1250px; 
    }
    .headerContainer .content {
        font-size: 1.4em;
    }
    .imageContainer {
        margin-left: -6.5%;
        margin-top: 150px;
    }
}